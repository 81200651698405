import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { formatContactNumber } from 'utils';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { MA_ROLE_KEY, NOT_REQUESTED, PENDING_KEY, REQUEST_TYPE } from 'constant';
import { getUser } from 'utils/UserDetails';
import { ADD_INVITATION_REQUEST } from 'pages/Profile/components/Broker/components/BrokerGraphQL';
import { filter } from 'lodash';
import { RoleContext } from 'context/Role';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { GET_RECOMMENDED_INVESTOR } from 'pages/FindMortgageAdmin/GraphQL';

const MAList = ({ refetch, limit = 2, isDashboard }) => {
  const { data: rolesList } = useContext(RoleContext);
  const isMob = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  const [sendInvite, { loading: addMALoading }] = useMutation(ADD_INVITATION_REQUEST);
  const {
    data,
    loading,
    refetch: refetchMA
  } = useQuery(GET_RECOMMENDED_INVESTOR, {
    variables: {
      offset: 0,
      limit: limit
    }
  });
  const user = getUser();
  const navigate = useNavigate();

  if (loading) {
    return <PageLoader />;
  }

  const mortgages = data?.getRecommendedMAsForInvestor?.maData
    ? data?.getRecommendedMAsForInvestor?.maData
    : [];

  const findRoleIdByKey = (key) => {
    const filterParamKey = filter(rolesList?.rolesList.roles, (item) => item.roleKey === key);
    if (filterParamKey.length) {
      return filterParamKey[0].id;
    }
  };

  const onRequestMA = async (id) => {
    try {
      await sendInvite({
        variables: {
          invitedTo: id,
          invitedBy: user.id,
          roleId: findRoleIdByKey(MA_ROLE_KEY),
          inviteRequestType: REQUEST_TYPE
        }
      });
      refetchMA();
      refetch();
    } catch (err) {
      refetch();
      toast.error(err.message);
    }
  };

  const renderMortgageAdmin = () => {
    return mortgages.map((mortgage) => (
      <div
        className={`${
          isMob ? 'd-block text-center' : 'd-flex'
        } p-4  align-items-center justify-content-between d-card ${!isDashboard ? 'mb-4' : ''}`}
        key={mortgage.id}>
        {mortgage?.logo ? (
          <img
            src={mortgage?.logo}
            alt="c-logo"
            className={`${isMob ? 'mb-3' : ''}`}
            style={{ height: '50px' }}
          />
        ) : (
          <img
            src={'https://image4.owler.com/logo/blossom-capital_owler_20201203_182705_original.png'}
            alt="c-logo"
            className={`${isMob ? 'mb-3' : ''}`}
          />
        )}
        <div className={`${isMob ? 'mb-3' : ''}`}>
          <p className="mb-1 font-size-15 semi-bold">{mortgage?.businessContacteMail || '-'}</p>
          <p className="mb-2 font-size-15 semi-bold">
            {mortgage?.businessContactPhoneNumber
              ? formatContactNumber(mortgage.businessContactPhoneNumber)
              : '-'}
          </p>
        </div>
        <div className="ms-4">
          {mortgage?.requestStatus === NOT_REQUESTED ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => onRequestMA(mortgage.id)}
              disabled={addMALoading}>
              Add as my MA
            </Button>
          ) : (
            <Button color="link" className="pe-none text-capitalize">
              Requested
            </Button>
          )}
        </div>
      </div>
    ));
  };

  const onHandleRoute = () => {
    navigate('/find-mortgage-admin');
  };

  return (
    <div>
      {renderMortgageAdmin()}
      <div className={`d-${isDashboard ? 'none' : 'flex'} justify-content-end`}>
        <Button color="link" className="px-0" onClick={onHandleRoute}>
          See more
        </Button>
      </div>
    </div>
  );
};

export default MAList;
