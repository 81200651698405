import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import { useOutletContext } from 'react-router-dom';
import CustomPhoneInput from 'components/common/Fields/CustomPhoneInput';
import FormikInputField from 'components/common/Fields/FormikInputField';
import CombineErrorMessage from 'components/CombineError';
import Logo from 'pages/Profile/common/Logo';

const BusinessContact = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { touched, errors, values } = useFormikContext();
  const { userPersonalDetails } = useOutletContext();

  useEffect(() => {
    const err = checkError();
    setHasError(err);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.website && touched.website) ||
      (errors.businessContactEmail && touched.businessContactEmail) ||
      (errors.businessContactPhoneNumber && touched.businessContactPhoneNumber) ||
      (errors.logo && touched.logo)
    );
  };

  const { bussinessType } = values;

  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Business Contact</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <Row>
            <Col md={12} lg={12} className="input-spacing">
              <Field
                size="lg"
                name="website"
                label="Website"
                component={FormikInputField}
                type="text"
                isError={false}
                isoptionallabel="true"
              />
            </Col>
            <Col md={12} lg={12} className="input-spacing">
              <Field
                size="lg"
                name="businessContactEmail"
                label="General Business Contact Email"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
            <Col>
              <Label>Business Contact Phone Number</Label>
              <Row className="input-spacing">
                <Col md={6} lg={6}>
                  <Field
                    size="lg"
                    id="businessContactPhoneNumber"
                    name="businessContactPhoneNumber"
                    placeholder="Enter Your Mobile Number"
                    component={CustomPhoneInput}
                    isError={false}
                    mb
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Logo
              optional
              userPersonalDetails={userPersonalDetails}
              isBusinessLogo={bussinessType !== 'sole_proprietor'}
            />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessContact;
