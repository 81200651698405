import React, { useEffect, useState } from 'react';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import CombineErrorMessage from 'components/CombineError';
import { useMediaQuery } from 'react-responsive';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';

const incomeArry = [
  'Under 75k $',
  '75k $ to 125k $',
  '126k $ to 300k $',
  '300k $ to 500k $',
  'Over 500k $'
];

const Income = ({ onUpdateValidation, isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const [allocationError, setAllocationError] = useState(false);
  const { values, errors, touched } = useFormikContext();
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  useEffect(() => {
    const allocationTouched = checkAllocation();
    if (allocationTouched) {
      const total = getAllocation(false);
      if (total !== 100) {
        setAllocationError(true);
      } else {
        setAllocationError(false);
      }
    } else {
      setAllocationError(false);
    }
  }, [touched, values.employment, values.interest, values.capitalGains, values.otherIncome]);

  useEffect(() => {
    if (values.otherIncome > 0) {
      onUpdateValidation(true);
    } else {
      onUpdateValidation(false, values.accountType);
    }
  }, [values.otherIncome]);

  const checkAllocation = () => {
    return touched.employment || touched.interest || touched.capitalGains || touched.otherIncome;
  };

  const checkError = () => {
    return (
      (errors.currentIncome && touched.currentIncome) ||
      (errors.isLevelOfIncome && touched.isLevelOfIncome) ||
      (errors.employment && touched.employment) ||
      (errors.interest && touched.interest) ||
      (errors.capitalGains && touched.capitalGains) ||
      (errors.otherIncome && touched.otherIncome) ||
      (errors.other && touched.other)
    );
  };

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const getAllocation = (flag) => {
    const { employment, interest, capitalGains, otherIncome } = values;
    const employPercent = employment ? Number(employment) : 0;
    const interestPercent = interest ? Number(interest) : 0;
    const capitalGainsPercent = capitalGains ? Number(capitalGains) : 0;
    const otherIncomePercent = otherIncome ? Number(otherIncome) : 0;
    const total = employPercent + interestPercent + capitalGainsPercent + otherIncomePercent;
    return flag ? `${total === 0 ? 100 : 100 - total}%` : total;
  };

  return (
    <div>
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          {allocationError && (
            <CombineErrorMessage>Total income allocation must be 100%</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Income</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <div className="input-spacing">
            <Label>What is your current income?</Label>
            <Row>
              {incomeArry.map((item, index) => {
                return (
                  <Col md={4} lg={4} className="mb-2" key={item}>
                    <Field
                      size="lg"
                      key={`${item}`}
                      id={item}
                      name="currentIncome"
                      label={item}
                      component={FormikRadioboxField}
                      isError={false}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="input-spacing">
            <Label>
              Is the level of income stated above comparable with your total income for the previous
              2 years ?
            </Label>
            <div className="d-flex">
              {answer.map(({ label, value }, index) => {
                return (
                  <div key={label} className={index === 1 ? 'ms-3' : ''}>
                    <Field
                      name="isLevelOfIncome"
                      type="radio"
                      label={label}
                      value={value}
                      checked={index === 1 ? !values.isLevelOfIncome : values.isLevelOfIncome}
                      component={FormikRadioField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="input-spacing">
            <Label>Estimate the source of total household income</Label>
            <p className="text-muted font-size-16">{getAllocation(true)} unallocated</p>
            <div>
              <Row>
                <Col md={6} lg={6}>
                  <Field
                    size="lg"
                    id="employment"
                    name="employment"
                    type="number"
                    component={FormikInputField}
                    label="Employment"
                    grouptextpercentage="true"
                    isError={false}
                  />
                </Col>
                <Col md={6} lg={6} className={`${isMobile ? 'mt-3' : ''}`}>
                  <Field
                    size="lg"
                    id="interest"
                    name="interest"
                    type="number"
                    component={FormikInputField}
                    label="Interest"
                    grouptextpercentage="true"
                    isError={false}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} lg={6}>
                  {' '}
                  <Field
                    size="lg"
                    id="capitalGains"
                    name="capitalGains"
                    type="number"
                    component={FormikInputField}
                    label="Capital Gains"
                    grouptextpercentage="true"
                    isError={false}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} lg={6}>
                  <Field
                    size="lg"
                    id="other"
                    name="otherIncome"
                    type="number"
                    component={FormikInputField}
                    label="Other"
                    grouptextpercentage="true"
                    isError={false}
                  />
                </Col>
                {values?.otherIncome !== '' && values.otherIncome > 0 ? (
                  <Col md={6} lg={6} className="mt-2">
                    <Field
                      size="lg"
                      id="specify"
                      name="other"
                      type="text"
                      component={FormikInputField}
                      placeholder="Please specify"
                      isError={false}
                      label=" "
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Income;
