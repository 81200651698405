import React, { useEffect, useReducer, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row
} from 'reactstrap';
import Contents from '../Contents';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AGENT_KEY,
  APPROVED_KEY,
  BROKERAGE_DETAILS_KEY,
  PERSONAL_INFO_KEY,
  REJECTED_KEY
} from 'constant';
import OnboardCard from '../Contents/OnboardCard';
import { getUnderReviewBadge, getVerifiedBadge } from 'pages/Profile/Helper';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import SubmittingFlow from './components/SubmittingFlow';
import RejectedCard from './components/SubmittingFlow/RejectedCard';
import Submission from 'pages/Profile/common/Submission';
import { getAccordionHeading } from './Helper';
import BrokerRequiredCard from './components/SubmittingFlow/components/BrokerRequiredCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CODE':
      return { ...state, code: action.payload };
    case 'UPDATE_COMPLETE':
      return { ...state, completed: action.payload, code: 0 };
    default:
      return state;
  }
};

const BrokerProfile = ({ userPersonalDetails, onRefetch }) => {
  const { isUnderReviewed, approveRequiredSections, approvedStatus } = userPersonalDetails;
  const [state, dispatch] = useReducer(reducer, {
    code: 0,
    completed: false
  });
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  const [open, setOpen] = useState(isTab ? '1' : null);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const navigate = useNavigate();
  const { state: params, pathname } = useLocation();

  useEffect(() => {
    if (
      userPersonalDetails?.isEsign &&
      userPersonalDetails?.isBrokerageInfo &&
      pathname === '/dashboard' &&
      userPersonalDetails?.approvedStatus !== REJECTED_KEY &&
      userPersonalDetails?.requestStatus !== REJECTED_KEY
    ) {
      dispatch({ type: 'UPDATE_COMPLETE', payload: true });
    }
  }, []);

  const { code, completed } = state;

  const updateActiveCode = (activeCode) => {
    document.querySelector('.contain-height').scrollTo(0, 0);
    onRefetch();
    dispatch({ type: 'UPDATE_CODE', payload: activeCode });
  };

  const renderContents = () => {
    if (userPersonalDetails?.requestStatus === REJECTED_KEY && pathname === '/dashboard') {
      return <RejectedCard user={userPersonalDetails} code={code} />;
    }
    return <Outlet context={{ userPersonalDetails, profileKey: AGENT_KEY, isProfile: false }} />;
  };

  const renderOnBoardRejectCard = () => {
    if (userPersonalDetails?.approvedStatus === REJECTED_KEY && pathname === '/dashboard') {
      return <Submission userDetails={userPersonalDetails} onlyCard />;
    }
    const emailAndPersonalInfo =
      !userPersonalDetails.isEmailVerified || !userPersonalDetails.isPersonalInfo;
    if (
      pathname === '/dashboard' &&
      userPersonalDetails?.approvedStatus !== REJECTED_KEY &&
      userPersonalDetails?.requestStatus !== REJECTED_KEY &&
      userPersonalDetails?.isBrokerageInfo
    ) {
      return <OnboardCard />;
    }
    if (
      pathname === '/dashboard' &&
      userPersonalDetails?.approvedStatus !== REJECTED_KEY &&
      userPersonalDetails?.requestStatus !== REJECTED_KEY &&
      emailAndPersonalInfo
    ) {
      return <OnboardCard />;
    }
    if (
      pathname === '/dashboard' &&
      userPersonalDetails.isEmailVerified &&
      userPersonalDetails.isPersonalInfo &&
      !userPersonalDetails?.isBrokerageInfo &&
      userPersonalDetails?.requestStatus !== REJECTED_KEY
    ) {
      return <BrokerRequiredCard />;
    }
  };

  const onHandleBack = () => {
    if (!userPersonalDetails.isEsign) {
      return navigate(`/users/onboarding`);
    } else if (userPersonalDetails.approvedStatus === REJECTED_KEY) {
      return navigate(`/approvals/rejected`);
    } else {
      navigate(`/${pathname.includes('approval') ? 'approvals' : 'users'}/brokerage`);
    }
  };

  const renderBackButton = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return (
        <Button color="link" className="p-0 font-size-14" onClick={onHandleBack}>
          <FontAwesomeIcon icon={faArrowLeft} className="font-size-14 me-2" />
          Back to {pathname.includes('approval') ? 'approvals' : 'users'}
        </Button>
      );
    }
    return null;
  };

  const checkProfileStatus = () => {
    if (approvedStatus === APPROVED_KEY && !isUnderReviewed) {
      return getVerifiedBadge();
    } else if (approvedStatus === APPROVED_KEY && isUnderReviewed) {
      return getUnderReviewBadge();
    }
  };

  const getHeading = () => {
    if (pathname.includes('complete-profile')) {
      return <h2 className="">Complete your Profile</h2>;
    }
    if (pathname.includes('approval'))
      return (
        <div className="mb-3 d-flex align-items-center ">
          <h2>Approve Agent</h2>
          {userPersonalDetails.approvedStatus === REJECTED_KEY && (
            <h5 className="ms-3 mb-0" style={{ color: '#EA6666' }}>
              REJECTED
            </h5>
          )}
        </div>
      );
    if (pathname.includes('profile') || pathname.includes('user'))
      return (
        <div className="d-flex align-items-center ">
          <h2>Profile</h2>
          {checkProfileStatus()}
        </div>
      );
    if (pathname.includes('dashboard')) {
      return <h2 className="">Hey {userPersonalDetails.preferredName}!</h2>;
    }
  };

  const getSectionApprovalStatus = () => {
    let sectionName = null;

    if (pathname.includes('personal-information')) {
      sectionName = PERSONAL_INFO_KEY;
    } else if (pathname.includes('brokerage-info')) {
      sectionName = BROKERAGE_DETAILS_KEY;
    }

    if (isUnderReviewed && pathname.includes('approval') && approveRequiredSections[sectionName]) {
      return true;
    }
    return false;
  };

  const isApproval = getSectionApprovalStatus();

  return (
    <div>
      {completed ? (
        <SubmittingFlow user={userPersonalDetails} />
      ) : (
        <div className={`${isApproval ? 'container-fluid' : 'container'}`}>
          <Row className="header-sticky">
            {/* <Col md={1} lg={1} /> */}
            <Col className="content-heading">
              {renderBackButton()}
              {getHeading()}
            </Col>
          </Row>
          <Row>
            {/* <Col md={1} lg={1} /> */}
            {!isTab ? (
              <Col md={isApproval ? 2 : 3} lg={isApproval ? 2 : 3}>
                <Contents
                  completed={completed}
                  code={code}
                  clickHandler={(codeNum) => {
                    if (codeNum === 1 && !params) return;
                    updateActiveCode(codeNum);
                  }}
                  onActiveEmail={updateActiveCode}
                  userPersonalDetails={userPersonalDetails}
                />
              </Col>
            ) : null}
            <Col
              md={isTab ? 10 : !isApproval ? 7 : ''}
              lg={isTab ? 10 : !isApproval ? 7 : ''}
              className="profile-height">
              {isTab ? (
                <Accordion
                  open={open}
                  toggle={toggle}
                  className="content-accordion"
                  defaultOpen="1">
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <h4 className={`mb-0 ${!open ? 'text-primary' : ''}`}>
                        {open ? 'Contents' : getAccordionHeading(pathname)}
                      </h4>
                    </AccordionHeader>
                    <AccordionBody accordionId="1" className="p-0">
                      <Contents
                        completed={completed}
                        code={code}
                        clickHandler={(codeNum) => {
                          if (codeNum === 1 && !params) return;
                          updateActiveCode(codeNum);
                        }}
                        onActiveEmail={updateActiveCode}
                        userPersonalDetails={userPersonalDetails}
                        isTab
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              ) : null}

              {renderContents()}
              {renderOnBoardRejectCard()}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default BrokerProfile;
