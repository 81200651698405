import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import StreetAddress from 'components/common/StreetAddress/streetAddress2';
import CombineErrorMessage from 'components/CombineError';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import { COUNTRIES, PROVINCES } from 'constant';

const PreviousAddress = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return (
      (errors.streetAddress2 && touched.streetAddress2) ||
      (errors.city2 && touched.city2) ||
      (errors.province2 && touched.province2) ||
      (errors.country2 && touched.country2) ||
      (errors.postalCode2 && touched.postalCode2)
    );
  };

  return (
    <div>
      <Card className="my-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Previous Address</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <Row>
            <Col md={12} lg={12} className="input-spacing">
              <StreetAddress
                name="streetAddress2"
                label="Street Address"
                isError={false}
                fillAddress={{
                  country: 'country2',
                  city: 'city2',
                  province: 'province2',
                  postalCode: 'postalCode2'
                }}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="preUnitNumber"
                name="unitNumber2"
                type="text"
                component={FormikInputField}
                label="Suite/Unit Number"
                isoptionallabel="true"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6}></Col>
          </Row>
          <Row>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="preCountry"
                name="country2"
                type="text"
                component={FormikSelectField}
                options={COUNTRIES}
                label="Country"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="preProvince"
                name="province2"
                type="text"
                component={FormikSelectField}
                options={PROVINCES}
                label="Province"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="preCity"
                name="city2"
                type="text"
                component={FormikInputField}
                label="City"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="prePostalCode"
                name="postalCode2"
                type="text"
                component={FormikInputField}
                label="Postal Code"
                isError={false}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default PreviousAddress;
