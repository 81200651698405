import * as Yup from 'yup';

const personalInfoValidation = {
  //address
  streetAddress1: Yup.string().trim().nullable().required('Please select street address'),
  unitNumber1: Yup.string().trim().nullable().max(20, 'Enter valid unit number'),
  country1: Yup.string().nullable().required('Please select country'),
  province1: Yup.string().nullable().required('Please select province'),
  city1: Yup.string().nullable().required('Please select city'),
  postalCode1: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(6, 'Postal code must be at least 6 characters long')
    .max(7, 'Postal code must be at most 7 characters long'),
  isSameAddress: Yup.boolean().nullable().required('Required'),
  //previous add
  streetAddress2: Yup.string()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string().trim().nullable().required('Field is required')
    }),
  unitNumber2: Yup.string()
    .trim()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string().trim().nullable().max(20, 'Enter valid unit number')
    }),
  country2: Yup.string()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string().trim().nullable().required('Field is required')
    }),
  province2: Yup.string()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string().trim().nullable().required('Field is required')
    }),
  city2: Yup.string()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string().trim().nullable().required('Field is required').max(50, 'Enter valid city')
    }),
  postalCode2: Yup.string()
    .trim()
    .nullable()
    .when('isSameAddress', {
      is: false,
      then: Yup.string()
        .trim()
        .nullable()
        .required('Field is required')
        .min(6, 'Postal code must be at least 6 characters long')
        .max(7, 'Postal code must be at most 7 characters long')
    }),
  licensedFsra: Yup.boolean().nullable().required('Required'),
  licenseNumber: Yup.string().nullable(),
  workedWithMA: Yup.boolean().nullable().required('Required'),
  mortgageAdmin: Yup.mixed(),
  mortgageAdminId: Yup.string()
    .nullable()
    .when('workedWithMA', {
      is: true,
      then: Yup.string().nullable().required('Required')
    }),
  //add info
  maritalStatus: Yup.string().nullable().required('Please choose marital status'),
  occupation: Yup.string()
    .trim()
    .nullable()
    .required('Please enter occupation')
    .min(3, 'Enter the valid identification number')
    .max(30, 'Enter the valid identification number'),
  //Identy
  identificationType: Yup.string().nullable().required('Please select the identification type'),
  identificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Please enter the identification number')
    .min(3, 'Enter the valid identification number')
    .max(40, 'Enter the valid identification number'),
  identificationDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the front identification'),
  identificationBackDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the back identification'),
  //Identy
  secondaryIdentificationType: Yup.string()
    .nullable()
    .required('Please select the identification type'),
  secondaryIdentificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Please enter the identification number')
    .min(3, 'Enter the valid identification number')
    .max(40, 'Enter the valid identification number'),
  secondaryIdentificationDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the front identification'),
  secondaryIdentificationBackDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the back identification'),
  //Profile
  profilePicture: Yup.string().nullable()
};

const licenseValidation = {
  licenseNumber: Yup.string()
    .trim()
    .nullable()
    .max(20, 'Enter valid license number')
    .required('Required')
};

const mortgageAdminValidation = {
  mortgageAdminId: Yup.mixed().nullable().required('Required')
};

export { personalInfoValidation, licenseValidation, mortgageAdminValidation };
