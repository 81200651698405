import * as Yup from 'yup';
import { validateNumber } from 'utils';

const businessInfoInitValue = {
  accountType: 'principal',
  bussinessType: '',
  maLicenceNumber: '',
  legalBusinessName: '',
  corporationNumber: '',
  partnershipNumber: '', //need to add
  jurisdiction: '', //need to add
  residency: '',
  isRegisteredBusinessName: '',
  operatingName: '',
  streetAddress: '',
  unitNumber: '',
  city: '',
  province: '',
  country: 'Canada',
  postalCode: '',
  principlerepresentativePosition: '',
  website: '',
  businessContactEmail: '',
  businessContactPhoneNumber: '',
  logo: '',
  identificationType: '',
  identificationNumber: '',
  identificationFrontDocs: '', //need 2 doc or include both in one
  identificationBackDocs: '',
  secondaryIdentificationType: '',
  secondaryIdentificationFrontDocs: '',
  secondaryIdentificationBackDocs: '',
  secondary_identification_number: '' //need 2 doc or include both in one
};

Yup.addMethod(Yup.string, 'validateNumber', validateNumber);

const businessInfoValidation = {
  accountType: Yup.string().nullable().required('Required'),
  bussinessType: Yup.string().nullable().required('Required'),
  maLicenceNumber: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .max(25, 'Please enter valid number'),
  isRegisteredBusinessName: Yup.string().nullable().required('Required'),
  incorporation: Yup.string().nullable(), //missing
  operatingName: Yup.string()
    .trim()
    .when('isRegisteredBusinessName', {
      is: (isRegistered) => {
        return isRegistered === 'true';
      },
      then: Yup.string().trim().nullable().required('required'),
      otherwise: Yup.string().trim().nullable()
    }),
  streetAddress: Yup.string().trim().nullable().required('Please select street address'),
  unitNumber: Yup.string().trim().nullable().max(25, 'Please enter valid unit number'),
  city: Yup.string().trim().nullable().required('Required'),
  province: Yup.string().trim().nullable().required('Required'),
  country: Yup.string().trim().nullable().required('Required'),
  postalCode: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(6, 'Postal code must be at least 6 characters long')
    .max(7, 'Postal code must be at most 7 characters long'), //missing
  principlerepresentativePosition: Yup.string()
    .nullable()
    .trim()
    .required('Required')
    .max(40, 'Please enter valid position'),

  identificationType: Yup.string().nullable().required('Required'),
  identificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .max(40, 'Please enter valid number'),
  identificationFrontDocs: Yup.mixed().required('Required'), //need 2 doc or include both in one
  identificationBackDocs: Yup.mixed().required('Required'),
  secondaryIdentificationType: Yup.string().nullable().required('Required'),
  secondaryIdentificationFrontDocs: Yup.mixed().required('Required'),
  secondaryIdentificationBackDocs: Yup.mixed().required('Required'),
  secondary_identification_number: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .max(40, 'Please enter valid number'), //need 2 doc or include both in one
  website: Yup.string()
    .trim()
    .nullable()
    .test('is-url-or-empty', 'Enter correct url or leave it empty', function (value) {
      if (!value) return true; // Allow empty value
      return /^(https?):\/\/(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        value
      );
    })
    .max(50, 'URL is too long'),
  businessContactEmail: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .max(35, 'Email is too long')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email'
    ),
  businessContactPhoneNumber: Yup.string().required('Required').validateNumber(),
  logo: Yup.string().nullable(),
  residency: Yup.string().when('bussinessType', (type, schema) => {
    return type === 'sole_proprietor' ? schema.nullable().required('required') : schema.nullable();
  }),
  corporationNumber: Yup.string().when('bussinessType', (type, schema) => {
    return type === 'corporation'
      ? schema.nullable().trim().max(25, 'Enter valid corporation number').required('Required')
      : schema.nullable();
  }),
  legalBusinessName: Yup.string().when('bussinessType', (type, schema) => {
    return type === 'corporation' || type === 'partnership'
      ? schema.nullable().trim().max(25, 'Enter valid corporation number').required('Required')
      : schema.nullable();
  }),
  jurisdiction: Yup.string().when('bussinessType', (type, schema) => {
    return type === 'corporation' || type === 'partnership'
      ? schema.nullable().trim().required('Required')
      : schema.nullable();
  }),
  partnershipNumber: Yup.string().when('bussinessType', (type, schema) => {
    return type === 'partnership'
      ? schema.nullable().trim().max(25, 'Enter valid partnership number').required('Required')
      : schema.nullable();
  })
};

const soleProprietorValidation = {
  residency: Yup.string().nullable().trim().required('Required')
};

const corporateValidation = {
  corporationNumber: Yup.string()
    .nullable()
    .trim()
    .max(25, 'Enter valid corporation number')
    .required('Required'),
  legalBusinessName: Yup.string()
    .nullable()
    .trim()
    .max(50, 'Enter valid business name')
    .required('Required'),
  jurisdiction: Yup.string().nullable().trim().required('Required')
};

const partnershipValidation = {
  partnershipNumber: Yup.string()
    .nullable()
    .trim()
    .max(25, 'Enter valid partnership number')
    .required('Required'),
  legalBusinessName: Yup.string()
    .nullable()
    .trim()
    .max(50, 'Enter valid business name')
    .required('Required'),
  jurisdiction: Yup.string().nullable().trim().required('Required')
};

const getAccordionHeading = (pathname) => {
  if (pathname === '/dashboard') {
    return 'Complete your Profile';
  }
  if (pathname.includes('personal')) return 'Personal Information';
  if (pathname.includes('business')) return 'Business Information';
  if (pathname.includes('lending-criteria')) return 'Lending Criteria';
  if (pathname.includes('investor')) return 'Investors';
  if (pathname.includes('email')) return 'Email Confirmation';
  if (pathname.includes('final')) return 'Final Approval';
  if (pathname.includes('e-sign')) return 'Signature';
};

export {
  businessInfoInitValue,
  businessInfoValidation,
  soleProprietorValidation,
  corporateValidation,
  partnershipValidation,
  getAccordionHeading
};
