import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { BROKERAGE_DETAILS_KEY } from 'constant';
import { checkIfFormValueExistInData } from 'pages/Profile/Helper';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import BusinessAddress from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessAddress';
import BusinessContact from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessContact';
import BusinessDetails from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessDetails';
import BusinessType from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessType';
import Loader from 'components/Loader';

const businessDetailsKeys = [
  'operatingName',
  'isRegisteredBusinessName',
  'residency',
  'jurisdiction',
  'partnershipNumber',
  'corporationNumber',
  'legalBusinessName',
  'brokerageLicenseNumber'
];
const businessAddressKeys = ['streetAddress', 'city', 'country', 'province', 'postalCode'];
const businessContactKeys = [
  'website',
  'businessContactEmail',
  'businessContactPhoneNumber',
  'logo'
];

const NewBrokerageForm = ({ userPersonalDetails, initValues, onApproveSection }) => {
  const { pathname } = useLocation();
  const isPathApproval = pathname.includes('approval');

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: BROKERAGE_DETAILS_KEY
    }
  });

  const renderFormActionButtons = (isSubmitting) => {
    if (isPathApproval) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              {...{
                type: 'submit',
                name: 'approveValue',
                value: true
              }}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
  };

  const newFormData = data?.getSectionUpdatedFields?.updatedFields || {};

  const checkBusinessDetails = checkIfFormValueExistInData(newFormData, businessDetailsKeys);
  const checkBusinessAddress = checkIfFormValueExistInData(newFormData, businessAddressKeys);
  const checkBusinessContact = checkIfFormValueExistInData(newFormData, businessContactKeys);

  return (
    <Formik
      initialValues={{ ...initValues, ...newFormData }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={onApproveSection}>
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && pathname.includes('approval')) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <BusinessType title="Business Type" onUpdateValidation={() => ''} />
          <BusinessDetails isNewData={checkBusinessDetails} licenseKey="brokerageLicenseNumber" />
          <BusinessAddress isNewData={checkBusinessAddress} />
          <BusinessContact isNewData={checkBusinessContact} />
          {renderFormActionButtons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default NewBrokerageForm;
