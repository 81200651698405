import * as Yup from 'yup';
import { validateNumber } from 'utils';
import { REQUIRED } from 'constant';

const approvePersonalInfoValidation = {
  firstName: Yup.string().nullable().required('required').max(50),
  lastName: Yup.string().nullable().required('required').max(50),
  preferredName: Yup.string().nullable().required('required').max(50),
  email: Yup.string().nullable().email('Invalid email').required('Required')
};

const brokerPersonalInfoValidation = {
  //address
  //license
  licenseNumber: Yup.string()
    .trim()
    .nullable()
    .required('License number is required')
    .min(5, 'Please enter valid number')
    .max(20, 'Please enter valid number'),

  //Identy
  identificationType: Yup.string().nullable().required('Please select the identification type'),
  identificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Please enter the identification number')
    .min(3, 'Enter valid number')
    .max(40, 'Enter valid number'),
  identificationDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the front identification'),
  identificationBackDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the back identification'),
  //Identy
  secondaryIdentificationType: Yup.string()
    .nullable()
    .required('Please select the identification type'),
  secondaryIdentificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Please enter the identification number')
    .min(3, 'Enter valid number')
    .max(40, 'Enter valid number'),
  secondaryIdentificationDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the front identification'),
  secondaryIdentificationBackDocs: Yup.string()
    .nullable()
    .required('Please ensure you upload the back identification'),
  //Profile
  profilePicture: Yup.string().nullable()
};

const personalInfoValues = {
  accountType: '',
  streetAddress1: '',
  unitNumber1: '',
  country1: 'Canada',
  province1: '',
  city1: '',
  postalCode1: '',
  isSameAddress: null,
  //secondary address
  streetAddress2: '',
  unitNumber2: '',
  country2: 'Canada',
  province2: '',
  city2: '',
  postalCode2: '',
  //licensing
  licensedFsra: null,
  licenseNumber: '',
  workedWithMA: null,
  mortgageAdmin: '',
  //Add Info
  maritalStatus: '',
  occupation: '',
  //Identy
  identificationType: '',
  identificationNumber: '',
  identificationDocs: '',
  identificationBackDocs: '',
  //Identy
  secondaryIdentificationType: '',
  secondaryIdentificationNumber: '',
  secondaryIdentificationDocs: '',
  secondaryIdentificationBackDocs: '',
  //Profile
  profilePicture: ''
};

const accountTypes = ['individual', 'trust', 'joint', 'corporation'];

const accountCommonInitValue = {
  //Benefi
  isBeneficiaries: '',
  beneficiaries: null,
  //Lending Knowledge
  knowledgeRwgardingMA: '',
  knowledgeRwgardingInvestor: '',
  //Income
  currentIncome: '',
  isLevelOfIncome: null,
  employment: '',
  interest: '',
  capitalGains: '',
  otherIncome: '',
  other: '',
  //Investment Goal
  investmentTimeHorizon: '',
  investmentObjectives: '',
  //Available Capital
  availableCapitalNow: '',
  availableCapitalIn6Months: ''
};

const trustAccountInitValues = {
  //Account Type
  accountTypeId: 1,
  accountName: '',
  isAccreditedInvestor: null,
  //Trust Info
  trustName: '',
  planType: '',
  accountNumber: '',
  isTrustMember: null,
  isCorporateMember: false,
  isJointMember: false,
  isIndividualMember: false,
  ...accountCommonInitValue
};

const corporateInitValue = {
  //Account Type
  accountTypeId: 2,
  accountName: '',
  isAccreditedInvestor: null,
  //details
  coorporateLegalName: '',
  coorporateOperatingName: '',
  identificationNumber: '',
  issuancePlace: '',
  //address
  streetAddress: '',
  unitNumber: '',
  city: '',
  province: '',
  country: 'Canada',
  postalCode: '',
  //business info
  contactEmail: '',
  contactPhoneNumber: '',
  website: '',
  logo: '',
  isDirector: true,
  ...accountCommonInitValue
};

const individualAccountInItValues = {
  accountName: '',
  accountTypeId: 3,
  isTrustMember: false,
  isCorporateMember: false,
  isJointMember: false,
  isIndividualMember: true,
  isAccreditedInvestor: null,
  ...accountCommonInitValue
};

const jointAccountInitValues = {
  userId: '',
  accountTypeId: 4,
  accountName: '',
  isAccreditedInvestor: null,
  accountNumber: '',
  memberId: 0,
  planType: '',
  isTrustMember: false,
  isCorporateMember: false,
  isJointMember: true,
  isIndividualMember: false,
  ...accountCommonInitValue
};

Yup.addMethod(Yup.string, 'validateNumber', validateNumber);

const memberBenifiValidation = Yup.object({
  beneficiaryLegalName: Yup.string().trim().required('Required'),
  beneficiaryLastName: Yup.string().trim().required('Required'),
  beneficiaryRelationAccount: Yup.string().trim().required('Required'),
  beneficiaryPhoneNo: Yup.string().required('Required').validateNumber()
});

const directorsValidation = Yup.object({
  beneficiaryLegalName: Yup.string().trim().required('Required'),
  beneficiaryLastName: Yup.string().trim().required('Required'),
  beneficiaryRelationAccount: Yup.string().trim().required('Required'),
  beneficiaryPhoneNo: Yup.string().required('Required').validateNumber()
});

const commonAccountValidation = {
  isBeneficiaries: Yup.string().nullable().required('Required'),
  beneficiaries: Yup.mixed(),
  knowledgeRwgardingMA: Yup.string().nullable().required('Required'),
  knowledgeRwgardingInvestor: Yup.string().nullable().required('Required'),
  currentIncome: Yup.string().trim().nullable().required('required'),
  isLevelOfIncome: Yup.boolean().nullable().required('required'),
  employment: Yup.number().nullable().min(0, 'Min can be 1').max(100, 'Max can be 100'),
  interest: Yup.number().nullable().min(0, 'Min can be 1').max(100, 'Max can be 100'),
  capitalGains: Yup.number().nullable().min(0, 'Min can be 1').max(100, 'Max can be 100'),
  otherIncome: Yup.number().nullable().min(0, 'Min can be 0').max(100, 'Max can be 100'),
  investmentTimeHorizon: Yup.string().nullable().required('Required'),
  investmentObjectives: Yup.string().nullable().required('Required'),
  availableCapitalNow: Yup.number().integer().nullable().required('Required').min(0),
  availableCapitalIn6Months: Yup.number().integer().nullable().required('Required').min(0)
};

const otherFieldValidation = {
  other: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(1, 'Please explain')
    .max(25, 'Enter valid explaination')
};

const individualAccountValidation = {
  accountName: Yup.string()
    .trim()
    .required('Required')
    .min(3, 'Enter valid name')
    .max(30, 'Enter valid name'),
  isAccreditedInvestor: Yup.boolean().nullable().required('Required'),
  ...commonAccountValidation
};

const jointAccountValidation = {
  accountName: Yup.string()
    .trim()
    .required('Required')
    .min(3, 'Enter valid name')
    .max(30, 'Enter valid name'),
  isAccreditedInvestor: Yup.boolean().nullable().required('Required'),
  ...commonAccountValidation
};

const trustAccountValidation = {
  accountName: Yup.string()
    .trim()
    .required('Required')
    .min(3, 'Enter valid name')
    .max(30, 'Enter valid name'),
  isAccreditedInvestor: Yup.boolean().nullable().required('Required'),
  trustName: Yup.string().required('Required').nullable().max(50, 'max can be 50'),
  planType: Yup.string().required('Required'),
  accountNumber: Yup.string().trim().nullable().required().max(50, 'Enter valid number'),
  isTrustMember: Yup.boolean().nullable().required('Required'),
  ...commonAccountValidation
};

const corporateAccountValidation = {
  accountName: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(2, 'Enter valid name')
    .max(25, 'Enter valid name'),
  coorporateLegalName: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(3, 'Enter valid name')
    .max(25, 'Enter valid name'),
  coorporateOperatingName: Yup.string().trim().nullable().max(25, 'Enter valid name'),
  identificationNumber: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(5, 'Enter valid number')
    .max(25, 'Enter valid number'),
  issuancePlace: Yup.string().trim().nullable().required('Required'),
  isAccreditedInvestor: Yup.boolean().nullable().required('Required'),
  streetAddress: Yup.string().nullable().required('Required'),
  unitNumber: Yup.string().trim().nullable().max(25, 'Enter valid number'),
  city: Yup.string().required('Required'),
  province: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  postalCode: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .min(6, 'Postal code must be at least 6 characters long')
    .max(7, 'Postal code must be at most 7 characters long'),
  isDirector: Yup.string().required('Required'),
  //business info
  contactEmail: Yup.string()
    .trim()
    .nullable()
    .required('Required')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email'
    ),
  contactPhoneNumber: Yup.string().trim().nullable().required('Required').validateNumber(),
  website: Yup.string()
    .trim()
    .nullable()
    .test('is-url-or-empty', 'Enter correct url or leave it empty', function (value) {
      if (!value) return true; // Allow empty value
      return /^(https?):\/\/(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        value
      );
    })
    .max(50, 'URL is too long'),
  logo: Yup.string(),
  ...commonAccountValidation
};

const getAccountInitialValue = (value) => {
  switch (value) {
    case 'trust':
      return { ...trustAccountInitValues };
    case 'individual':
      return { ...individualAccountInItValues };
    case 'corporation':
      return { ...corporateInitValue };
    case 'joint':
      return { ...jointAccountInitValues };
    default:
      return {};
  }
};

const lendingCriteriaInitValues = {
  maxGDSSelect: false,
  maxTDSSelect: false,
  maxCreditScoreSelect: false,
  documentationImportance: null,
  maximumGDS: null,
  maximumTDS: null,
  maximumCreditScore: '',
  isFullyOpen: '',
  lendingPositions: null,
  availableTermLength: '',
  availableAmortization: '',
  maximumMortgageSize: '',
  minimumMortgageSize: '',
  minimumRate: '',
  maximumRate: '',
  minimumFree: '',
  maximumFree: '',
  maximumLTVOnProperty: [
    { label: 'Single Family Resident', value: null, isShow: false },
    { label: 'Condominium', value: null, isShow: false },
    { label: 'Condo Townhouse', value: null, isShow: false },
    { label: 'Mixed Use', value: null, isShow: false },
    { label: 'Construction', value: null, isShow: false },
    { label: 'Well And Septic', value: null, isShow: false },
    { label: 'Rental', value: null, isShow: false },
    { label: 'Rooming', value: null, isShow: false },
    { label: 'Agriculture Zoned', value: null, isShow: false },
    { label: 'Farm', value: null, isShow: false }
  ],
  maximumLTVOnLocation: [
    { label: 'Greater Toronto Area', value: null, isShow: false },
    { label: 'London Area', value: null, isShow: false },
    { label: 'Niagara Area', value: null, isShow: false },
    { label: 'Sarnia Area', value: null, isShow: false },
    { label: 'Hamilton Area', value: null, isShow: false },
    { label: 'Barrie Area', value: null, isShow: false },
    { label: 'Owen Sound', value: null, isShow: false },
    { label: 'Peterborough', value: null, isShow: false },
    { label: 'Kingston', value: null, isShow: false },
    { label: 'Ottawa', value: null, isShow: false },
    { label: 'North Ontario', value: null, isShow: false }
  ]
};

const lendingCriteriaValidation = Yup.object({
  documentationImportance: Yup.number().nullable().required('Required'),
  maximumGDS: Yup.number()
    .nullable()
    .min(0, 'Please ensure you enter Maximum GDS that is between 0 to 100')
    .max(100, 'Please ensure you enter Maximum GDS that is between 0 to 100')
    .required(REQUIRED),
  maximumTDS: Yup.number()
    .nullable()
    .min(0, 'Please ensure you enter Maximum TDS that is between 0 to 100')
    .max(100, 'Please ensure you enter Maximum TDS that is between 0 to 100')
    .required(REQUIRED),
  maximumCreditScore: Yup.number()
    .nullable()
    .required(REQUIRED)
    .min(0, 'Please ensure you enter Credit Score that is between 0 to 850')
    .max(850, 'Please ensure you enter Credit Score that is between 0 to 850'),
  isFullyOpen: Yup.string().nullable().required('Required'),
  availableTermLength: Yup.array()
    .nullable()
    .required('Please select at least one')
    .min(1, 'Required'),
  availableAmortization: Yup.array()
    .nullable()
    .required('Please select at least one')
    .min(1, 'Required'),
  maximumMortgageSize: Yup.number()
    .nullable()
    .min(0, 'Invalid mortgage size')
    .required('Required')
    .test(
      'minimumMortgageSize',
      'Max Size must be greater than or equal to Min Size',
      function (maxSize) {
        const minSize = this.parent.minimumMortgageSize; // Access minSize value
        return maxSize >= minSize; // Check if maxSize is greater than or equal to minSize
      }
    ),
  // .matches(/^(\d*\.)?\d+$/, 'No decimals')
  minimumMortgageSize: Yup.number().nullable().min(0, 'Invalid mortgage size').required('Required'),
  minimumRate: Yup.number()
    .nullable()
    .min(0.01, 'Invalid Rate')
    .max(100, 'Min rate can be 100')
    .required('Required'),
  maximumRate: Yup.number()
    .nullable()
    .min(0.01, 'Invalid Rate')
    .max(100, 'Max rate can be 100')
    .required('Required')
    .test('minimumRate', 'Max Size must be greater than or equal to Min Size', function (maxSize) {
      const minSize = this.parent.minimumRate; // Access minSize value
      return maxSize >= minSize; // Check if maxSize is greater than or equal to minSize
    }),
  minimumFree: Yup.number()
    .nullable()
    .min(0, 'Invalid Fee')
    .max(100, 'Min fee can be 100')
    .required('Required'),
  maximumFree: Yup.number()
    .nullable()
    .min(0, 'Invalid Fee')
    .max(100, 'Max fee can be 100')
    .required('Required')
    .test('minimumFree', 'Max Size must be greater than or equal to Min Size', function (maxSize) {
      const minSize = this.parent.minimumFree; // Access minSize value
      return maxSize >= minSize; // Check if maxSize is greater than or equal to minSize
    }),
  maximumLTVOnProperty: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(), // these constraints take precedence
        isShow: Yup.boolean().nullable(),
        value: Yup.number().when('isShow', {
          is: true,
          then: Yup.number()
            .nullable()
            .min(0, 'min is 0')
            .max(100, 'max can be 100')
            .required('Required'),
          otherwise: Yup.number().nullable().min(0, 'min is 0').max(100, 'max can be 100')
        })
      })
    )
    .test({
      message: 'You must set a maximum LTV for at least one property type',
      test: (val) => {
        const filter = val.filter((item) => item.isShow === true);
        return filter.length;
      }
    }),
  // .required('You must set a maximum LTV for at least one property type')
  // .min(1, 'Required')
  maximumLTVOnLocation: Yup.array()
    .of(
      Yup.object().shape({
        isShow: Yup.boolean().nullable(),
        label: Yup.string(), // these constraints take precedence
        value: Yup.number().when('isShow', {
          is: true,
          then: Yup.number()
            .nullable()
            .min(0, 'min is 0')
            .max(100, 'max can be 100')
            .required('Required'),
          otherwise: Yup.number().nullable().min(0, 'min is 0').max(100, 'max can be 100')
        })
        // these constraints take precedence
      })
    )
    .test({
      message: 'You must set a maximum LTV for at least one property type',
      test: (val) => {
        const filter = val.filter((item) => item.isShow === true);
        return filter.length;
      }
    }),
  // .min(1, 'Required')
  lendingPositions: Yup.array().nullable().required('Lending position').min(1, 'Required')
});

const filogixIntegrationValidation = {
  clientSystem: Yup.string().nullable().required('required'),
  orgId: Yup.string().nullable().required('required'),
  firmCode: Yup.string().nullable().required('required'),
  userId: Yup.string().nullable().required('Required')
};

export {
  personalInfoValues,
  approvePersonalInfoValidation,
  getAccountInitialValue,
  accountTypes,
  memberBenifiValidation,
  directorsValidation,
  lendingCriteriaInitValues,
  lendingCriteriaValidation,
  //Account Validation
  otherFieldValidation,
  trustAccountValidation,
  corporateAccountValidation,
  jointAccountValidation,
  individualAccountValidation,
  brokerPersonalInfoValidation,
  filogixIntegrationValidation
};
