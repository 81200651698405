import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import CombineErrorMessage from 'components/CombineError';

const AdditionalInfo = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return (
      (errors.maritalStatus && touched.maritalStatus) || (errors.occupation && touched.occupation)
    );
  };
  const maritalOpt = ['Single', 'Widowed', 'Separated', 'Married', 'Divorced', 'Common-law'];
  return (
    <div className="my-4">
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Additional Information</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <Label>What is your marital status?</Label>
          <Row className="input-spacing w-100">
            {maritalOpt.map((item, index) => {
              return (
                <Col md={4} lg={4} sm={12} key={item} className="mb-0">
                  <Field
                    size="lg"
                    key={item}
                    id={`${item.toLowerCase()}`}
                    name="maritalStatus"
                    component={FormikRadioboxField}
                    label={item}
                    isError={false}
                  />
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="occupation"
                name="occupation"
                type="text"
                component={FormikInputField}
                label="Occupation"
                isError={false}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdditionalInfo;
