import { gql } from '@apollo/client';

const ADD_PB_DETAIL = gql`
  mutation AddPrincipalBrokerageDetails(
    $userId: Int
    $bussinessType: String
    $brokerageLicenseNumber: String
    $legalBusinessName: String
    $corporationNumber: String
    $partnershipNumber: String
    $operatingName: String
    $jurisdiction: String
    $residency: String
    $isRegisteredBusinessName: Boolean
    $streetAddress: String
    $unitNumber: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $website: String
    $businessContactEmail: String
    $businessContactPhoneNumber: String
    $businessContactNumberType: String
    $logo: String
  ) {
    addPrincipalBrokerageDetails(
      userId: $userId
      bussinessType: $bussinessType
      brokerageLicenseNumber: $brokerageLicenseNumber
      legalBusinessName: $legalBusinessName
      corporationNumber: $corporationNumber
      partnershipNumber: $partnershipNumber
      operatingName: $operatingName
      jurisdiction: $jurisdiction
      residency: $residency
      isRegisteredBusinessName: $isRegisteredBusinessName
      streetAddress: $streetAddress
      unitNumber: $unitNumber
      city: $city
      province: $province
      country: $country
      postalCode: $postalCode
      website: $website
      businessContactEmail: $businessContactEmail
      businessContactPhoneNumber: $businessContactPhoneNumber
      businessContactNumberType: $businessContactNumberType
      logo: $logo
    )
  }
`;

const UPDATE_PB_DETAILS = gql`
  mutation UpdatePrincipalBrokerageDetails(
    $userId: Int!
    $bussinessType: String
    $brokerageLicenseNumber: String
    $legalBusinessName: String
    $partnershipNumber: String
    $corporationNumber: String
    $operatingName: String
    $jurisdiction: String
    $residency: String
    $isRegisteredBusinessName: Boolean
    $streetAddress: String
    $unitNumber: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $website: String
    $businessContactEmail: String
    $businessContactNumberType: String
    $businessContactPhoneNumber: String
    $logo: String
    $isUpdating: Boolean
    $formSection: String
  ) {
    updatePrincipalBrokerageDetails(
      userId: $userId
      bussinessType: $bussinessType
      brokerageLicenseNumber: $brokerageLicenseNumber
      legalBusinessName: $legalBusinessName
      partnershipNumber: $partnershipNumber
      corporationNumber: $corporationNumber
      operatingName: $operatingName
      jurisdiction: $jurisdiction
      residency: $residency
      isRegisteredBusinessName: $isRegisteredBusinessName
      streetAddress: $streetAddress
      unitNumber: $unitNumber
      city: $city
      province: $province
      country: $country
      postalCode: $postalCode
      website: $website
      businessContactEmail: $businessContactEmail
      businessContactNumberType: $businessContactNumberType
      businessContactPhoneNumber: $businessContactPhoneNumber
      logo: $logo
      isUpdating: $isUpdating
      formSection: $formSection
    )
  }
`;

const PRINCIPAL_BROKER_DETAILS = gql`
  query getPrincipalBrokerageDetails($userId: Int!) {
    getPrincipalBrokerageDetails(userId: $userId)
  }
`;

export { ADD_PB_DETAIL, PRINCIPAL_BROKER_DETAILS, UPDATE_PB_DETAILS };
