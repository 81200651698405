import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PB_DETAIL, PRINCIPAL_BROKER_DETAILS, UPDATE_PB_DETAILS } from '../../PBGraphQL';
import { getUser } from 'utils/UserDetails';
import BusinessAddress from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessAddress';
import BusinessContact from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessContact';
import BusinessDetails from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessDetails';
import BusinessType from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessType';
import {
  principalBrokerInitValue,
  principalBrokerValidation,
  soleProprietorValidation,
  corporateValidation,
  partnershipValidation
} from '../Helper';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import { BROKERAGE_DETAILS_KEY, PENDING_KEY, REJECTED_KEY } from 'constant';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getUpdateValuesFromObj, updatePBAgentApproveSection } from 'pages/Profile/Helper';
import ErrorScroll from 'components/ErrorScroll';
import Loader from 'components/Loader';
import NewBrokerageForm from './NewBrokerageForm';

const BrokerageDetails = () => {
  const [initValues, setInitValues] = useState(principalBrokerInitValue);
  const [validationSchema, setValidationSchema] = useState(principalBrokerValidation);

  const { state: param, pathname } = useLocation();
  const user = getUser();
  const navigate = useNavigate();
  const [addBrokerDetails, { loading }] = useMutation(ADD_PB_DETAIL);
  const [updateBrokerDetails] = useMutation(UPDATE_PB_DETAILS);
  const { userPersonalDetails } = useOutletContext();
  const [approveSection, { loading: approveLoading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const { id } = useParams();
  const { data: brokerDetailsData } = useQuery(PRINCIPAL_BROKER_DETAILS, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });

  const isCompleteProfile = pathname.includes('complete-profile');
  const isProfile = pathname.includes('profile');
  const isPathApproval = pathname.includes('approval');

  useEffect(() => {
    if (brokerDetailsData?.getPrincipalBrokerageDetails) {
      setInitValues({
        ...brokerDetailsData.getPrincipalBrokerageDetails
      });
    }
  }, [brokerDetailsData]);

  const updatePBDetails = async (data) => {
    if (data.website) {
      data.website = data.website.trim();
    }

    try {
      const allValues = getUpdateValuesFromObj(initValues, data);
      if (!isCompleteProfile && isProfile) {
        allValues.isUpdating = true;
        allValues.formSection = BROKERAGE_DETAILS_KEY;
      }
      const { data: result } = await updateBrokerDetails({
        variables: { userId: parseInt(user?.id), ...allValues }
      });
      toast.success(result.updatePrincipalBrokerageDetails.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = async (data) => {
    if (data && !data.isRegisteredBusinessName) {
      data.operatingName = null;
    }
    if (isPathApproval) return onApproveSection(data);
    if (userPersonalDetails?.isBrokerageInfo) return updatePBDetails(data);
    try {
      if (data.website) {
        data.website = data.website.trim();
        data.website =
          data?.website.includes('https') || data?.website.includes('http')
            ? data?.website
            : `https://${data.website}`;
      }

      const { data: result } = await addBrokerDetails({ variables: { ...data, userId: user.id } });
      toast.success(result?.addPrincipalBrokerageDetails.message);
      navigate(`/complete-profile/e-sign`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onApproveSection = async (data) => {
    const approveValue = data?.approveValue === 'true' ? true : false;
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updatePBAgentApproveSection(
            userPersonalDetails.approveSections,
            BROKERAGE_DETAILS_KEY,
            approveValue
          ),
          status: approveValue ? 'approved' : 'rejected',
          formSection: BROKERAGE_DETAILS_KEY
        }
      });
      navigate(`/approval/brokerage/${id}/e-sign`);
      toast.success(result.approveUserBySuperAdmin.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onUpdateValidation = (e) => {
    const { value } = e.target;
    switch (value) {
      case 'sole_proprietor':
        return setValidationSchema({ ...principalBrokerValidation, ...soleProprietorValidation });
      case 'partnership':
        return setValidationSchema({ ...principalBrokerValidation, ...partnershipValidation });
      case 'corporation':
        return setValidationSchema({ ...principalBrokerValidation, ...corporateValidation });
      default:
        break;
    }
  };

  const renderSubmitButton = () => {
    return loading ? (
      <Loader />
    ) : (
      <Button color="primary" type="submit" size="lg">
        {pathname.includes('complete-profile') ? 'Save and Continue' : 'Update'}
      </Button>
    );
  };

  const renderFormActionAction = () => {
    if (
      isPathApproval &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <>
          <Button
            color="danger"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}>
            Reject
          </Button>
          <Button
            color="primary"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}>
            Approve Section
          </Button>
        </>
      );
    }
    if (pathname.includes('profile')) {
      return renderSubmitButton();
    }
  };

  const renderNewDataForm = () => {
    if (
      userPersonalDetails.isUnderReviewed &&
      isPathApproval &&
      userPersonalDetails.approveRequiredSections[BROKERAGE_DETAILS_KEY]
    )
      return (
        <Col>
          <NewBrokerageForm
            userPersonalDetails={userPersonalDetails}
            initValues={initValues}
            onApproveSection={onApproveSection}
          />
        </Col>
      );
  };

  return (
    <Row>
      <Col>
        <Formik
          initialValues={initValues}
          validationSchema={param?.approve ? Yup.object().shape({}) : Yup.object(validationSchema)}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validateOnMount={true}>
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <form
              onSubmit={(e) => {
                const submitter = e.nativeEvent?.submitter;
                if (submitter?.name && submitter?.value && isPathApproval) {
                  setFieldValue(submitter.name, submitter.value);
                }
                handleSubmit(e);
              }}>
              <ErrorScroll />
              <BusinessType title="Business Type" onUpdateValidation={onUpdateValidation} />
              {values?.bussinessType && (
                <Fragment>
                  <BusinessDetails licenseKey="brokerageLicenseNumber" />
                  <BusinessAddress />
                  <BusinessContact />
                </Fragment>
              )}
              <div className="mt-4 text-end">{renderFormActionAction()}</div>
            </form>
          )}
        </Formik>
      </Col>
      {renderNewDataForm()}
    </Row>
  );
};

export default BrokerageDetails;
