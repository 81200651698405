import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Field, useFormikContext } from 'formik';
import BenifiMembers from '../BenifiMembers';
import MemberBenefiModal from '../MemberBenefiModal';
import { Card, CardBody, Label } from 'reactstrap';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import CombineErrorMessage from 'components/CombineError';
import { useLazyQuery } from '@apollo/client';
import { GET_BENEFICIARIES } from 'pages/Investors/components/InvestorDetails/components/Accounts/components/Beneficiaries/GraphQL';
import { getUser } from 'utils/UserDetails';

const Beneficiaries = ({ updateBenifi, beneficiaries, userPersonalDetails }) => {
  const [state, setState] = useState({
    initialValues: {},
    benifiModal: false,
    editBenifiData: ''
  });
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const [fetchBenefi, { data: fetchedBenefi, refetch }] = useLazyQuery(GET_BENEFICIARIES, {
    variables: {
      userId: Number(userPersonalDetails.id),
      investorAccountId: values.id
    }
  });

  const allBenefi = fetchedBenefi?.getBeneficiary?.beneficiarys
    ? fetchedBenefi?.getBeneficiary?.beneficiarys
    : [];

  const { benifiModal, editBenifiData } = state;

  useEffect(() => {
    if (values?.id) {
      fetchBenefi();
    }
  }, []);

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return errors.isBeneficiaries && touched.isBeneficiaries;
  };

  const getBeneficiaries = (data) => {
    if (data.id) {
      const find = beneficiaries.filter((item) => item.id !== data.id);
      setState((pre) => ({
        ...pre,
        benifiModal: false,
        editBenifiData: ''
      }));
      updateBenifi([...find, { ...data, id: data.id }]);
    } else {
      const read = { ...data, id: uuidv4() };
      updateBenifi([...beneficiaries, { ...read }]);
      setState((pre) => ({
        ...pre,
        benifiModal: false,
        editBenifiData: ''
      }));
    }
  };

  const onEditBenifi = (id) => {
    const find = [...allBenefi, ...beneficiaries].filter((item) => item.id === id);
    setState((pre) => ({
      ...pre,
      editBenifiData: find[0],
      benifiModal: true
    }));
  };

  const onRemoveBenifi = (id) => {
    const find = beneficiaries.filter((item) => item.id !== id);
    if (!find?.length) {
      setFieldValue('isBeneficiaries', false);
    }
    updateBenifi([...find]);
  };

  const onAddBenifi = () => {
    setState((pre) => ({
      ...pre,
      benifiModal: true
    }));
  };

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const onHandleClick = (e) => {
    const { value } = e.target;
    if (value === 'false') {
      updateBenifi([]);
    }
    setState((pre) => ({
      ...pre,
      benifiModal: value === 'true'
    }));
  };

  const onCloseBenfeModal = (flag = false) => {
    const isBenefi = allBenefi.length || beneficiaries.length;
    if (isBenefi && !flag) {
      setFieldValue('isBeneficiaries', true);
    } else {
      setFieldValue('isBeneficiaries', flag);
    }
    setState((pre) => ({
      ...pre,
      benifiModal: false,
      editBenifiData: ''
    }));
  };

  return (
    <div>
      <Card className="my-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <h3 className="card-heading"> Beneficiaries</h3>
          <div className="input-spacing">
            <Label>
              Are there any persons or entities other than the named person(s) on the account, for
              whose benefit the account is being operated
            </Label>
            <div className="d-flex">
              {answer.map(({ label, value }, index) => {
                return (
                  <div key={label} className={index === 1 ? 'ms-3' : ''}>
                    <Field
                      name="isBeneficiaries"
                      type="radio"
                      label={label}
                      value={value}
                      onClick={onHandleClick}
                      checked={index === 1 ? !values.isBeneficiaries : values.isBeneficiaries}
                      component={FormikRadioField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {benifiModal ? (
              <MemberBenefiModal
                title="Beneficiaries"
                editTitle="Edit Beneficiaries"
                open={benifiModal}
                close={onCloseBenfeModal}
                getData={getBeneficiaries}
                values={editBenifiData}
                refetch={refetch}
                isBenefi
              />
            ) : null}
          </div>
        </CardBody>
      </Card>
      {allBenefi.length || beneficiaries.length ? (
        <BenifiMembers
          title="Beneficiaries"
          addButtonTitle="Add Beneficiary"
          members={[...allBenefi, ...beneficiaries]}
          onEdit={onEditBenifi}
          onRemove={onRemoveBenifi}
          onAddMember={onAddBenifi}
          refetch={refetch}
          isBenefi
        />
      ) : null}
    </div>
  );
};

export default Beneficiaries;
