import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Card, CardBody, Col, Row } from 'reactstrap';
import StreetAddress from 'components/common/StreetAddress/index';
import CombineErrorMessage from 'components/CombineError';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import { COUNTRIES, PROVINCES } from 'constant';

const BusinessAddress = ({ isNewData, title = 'Corporate Address' }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.streetAddress && touched.streetAddress) ||
      (errors.city && touched.city) ||
      (errors.country && touched.country) ||
      (errors.province && touched.province) ||
      (errors.postalCode && touched.postalCode)
    );
  };

  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}

          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">
              {values.bussinessType === 'sole_proprietor' || values.bussinessType === 'partnership'
                ? 'Business Address'
                : title}
            </h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>

          <Row>
            <Col className="input-spacing" md={12} lg={12}>
              <StreetAddress
                name="streetAddress"
                label="Street Address"
                fillAddress={{
                  country: 'country',
                  city: 'city',
                  province: 'province',
                  postalCode: 'postalCode'
                }}
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="unitNumber"
                label="Suite/Unit Number"
                component={FormikInputField}
                type="text"
                isError={false}
                isoptionallabel="true"
              />
            </Col>
            <Col md={6} lg={6}></Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="country"
                label="Country"
                component={FormikSelectField}
                options={COUNTRIES}
                type="text"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="province"
                label="Province"
                component={FormikSelectField}
                options={PROVINCES}
                type="text"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="city"
                label="City"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="postalCode"
                label="Postal code"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessAddress;
