import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Address from './component/Address';
import { PERSONAL_INFO_KEY } from 'constant';
import PreviousAddress from './component/PreviousAddress';
import Licensing from './component/LicenseAdmin';
import AdditionalInfo from './component/AdditionalInfo';
import Identification from 'pages/Profile/common/Identy';
import ApprovePersonalInfo from 'pages/Profile/common/ApprovePersonalInfo';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import {
  checkIfFormValueExistInData,
  personalInfoKeys,
  updateInvestorApproveSection
} from 'pages/Profile/Helper';
import ProfilePicture from 'pages/Profile/common/ProfilePic';
import Loader from 'components/Loader';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';

const addressKeys = [
  'streetAddress1',
  'city1',
  'province1',
  'country1',
  'postalCode1',
  'isSameAddress'
];
const addressKeys2 = [
  'streetAddress2',
  'unitNumber2',
  'country2',
  'province2',
  'city2',
  'postalCode2'
];
const licenseKeys = ['licensedFsra', 'licenseNumber'];
const additionalInfoKeys = ['maritalStatus', 'occupation'];
const identyKeys = [
  'identificationType',
  'identificationNumber',
  'identificationDocs',
  'identificationBackDocs'
];
const secondIdentyKeys = [
  'secondaryIdentificationType',
  'secondaryIdentificationNumber',
  'secondaryIdentificationDocs',
  'secondaryIdentificationBackDocs'
];

const NewDataPersonalInfoForm = ({ userPersonalDetails }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: PERSONAL_INFO_KEY
    }
  });

  const [approveSection] = useMutation(APPROVE_USER_BY_ADMIN);

  const isPathApproval = pathname.includes('approval');

  const updatedData = data?.getSectionUpdatedFields?.updatedFields || {};

  if (isEmpty(updatedData)) {
    return null;
  }

  const onSubmit = async (data) => {
    const approveValue = data.approveValue === 'true' ? true : false;
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updateInvestorApproveSection(
            userPersonalDetails.approveSections,
            PERSONAL_INFO_KEY,
            approveValue
          ),
          status: approveValue ? 'approved' : 'rejected',
          formSection: PERSONAL_INFO_KEY
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      navigate(`/approval/investor/${id}/accounts`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderFormActionButtons = (isSubmitting) => {
    if (isPathApproval) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              {...{
                type: 'submit',
                name: 'approveValue',
                value: true
              }}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
    if (pathname.includes('profile')) {
      return <div className="mt-4 text-end">{renderSubmitButton(isSubmitting)}</div>;
    }
  };

  const checkFirstAddress = checkIfFormValueExistInData(updatedData, addressKeys);
  const checkSecondAddress = checkIfFormValueExistInData(updatedData, addressKeys2);
  const checkLicenseKey = checkIfFormValueExistInData(updatedData, licenseKeys);
  const checkAdditionalInfoKeys = checkIfFormValueExistInData(updatedData, additionalInfoKeys);
  const checkIdentyKeys = checkIfFormValueExistInData(updatedData, identyKeys);
  const checkSecondIdentyKeys = checkIfFormValueExistInData(updatedData, secondIdentyKeys);
  const checkPersonalInfoKeys = checkIfFormValueExistInData(updatedData, personalInfoKeys);

  return (
    <Formik
      initialValues={{ ...userPersonalDetails, ...updatedData }}
      validationSchema={Yup.object().shape({})}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={true}>
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && id) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <ApprovePersonalInfo
            isForm
            userPersonalDetails={userPersonalDetails}
            isNewData={checkPersonalInfoKeys}
          />
          <Address isNewData={checkFirstAddress} />
          {typeof values.isSameAddress === 'boolean' && !values.isSameAddress && (
            <PreviousAddress isNewData={checkSecondAddress} />
          )}
          <Licensing isNewData={checkLicenseKey} updateValidationFromLicense={() => ''} />
          <AdditionalInfo isNewData={checkAdditionalInfoKeys} />
          <Identification
            title="Identification"
            formKey={{
              forntDoc: 'identificationDocs',
              backDoc: 'identificationBackDocs',
              identyType: 'identificationType',
              identyNum: 'identificationNumber'
            }}
            identyTypesKey={{
              primary: 'identificationType',
              secondary: 'secondaryIdentificationType'
            }}
            isNewData={checkIdentyKeys}
          />
          <Identification
            title="Secondary Identification"
            formKey={{
              forntDoc: 'secondaryIdentificationDocs',
              backDoc: 'secondaryIdentificationBackDocs',
              identyType: 'secondaryIdentificationType',
              identyNum: 'secondaryIdentificationNumber'
            }}
            secondary
            identyTypesKey={{
              primary: 'identificationType',
              secondary: 'secondaryIdentificationType'
            }}
            isNewData={checkSecondIdentyKeys}
          />
          <ProfilePicture userPersonalDetails={{ ...userPersonalDetails, ...updatedData }} />
          {renderFormActionButtons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default NewDataPersonalInfoForm;
