import React from 'react';
import BusinessInfo from './components/BusinessInfo';
import CorporateAddress from './components/CorporateAddress';
import CorporateDetails from './components/CorporateDetails';
import Directors from './components/Directors';

const Corporation = ({ updateDirectors, directors, userPersonalDetails, isNewData }) => {
  const {
    isCorporateDetailNewData,
    isCorporateAddressNewData,
    isBusinessInfoNewData,
    isNewForm,
    isNewDataForm,
    isDirectorNewData
  } = isNewData || {};

  return (
    <div>
      <CorporateDetails isNewData={isCorporateDetailNewData} />
      <CorporateAddress isNewData={isCorporateAddressNewData} />
      <BusinessInfo isNewData={isBusinessInfoNewData} />
      <Directors
        updateBenifi={updateDirectors}
        directors={directors}
        userPersonalDetails={userPersonalDetails}
        isNewForm={isNewForm}
        isNewDataForm={isNewDataForm}
        isNewData={isDirectorNewData}
      />
    </div>
  );
};

export default Corporation;
