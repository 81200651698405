import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Licensing from './components/Licensing';
import Identification from 'pages/Profile/common/Identy';
import ApprovePersonalInfo from 'pages/Profile/common/ApprovePersonalInfo';
import { BROKERAGE_DETAILS_KEY, BROKER_ROLE_KEY, PB_ROLE_KEY, PERSONAL_INFO_KEY } from 'constant';
import { RoleContext } from 'context/Role';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import {
  checkIfFormValueExistInData,
  personalInfoKeys,
  updatePBAgentApproveSection
} from 'pages/Profile/Helper';
import ProfilePicture from 'pages/Profile/common/ProfilePic';
import Loader from 'components/Loader';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import SelectedBroker from './components/SelectedBroker';

const identyKeys = [
  'identificationType',
  'identificationNumber',
  'identificationDocs',
  'identificationBackDocs'
];
const secondIdentyKeys = [
  'secondaryIdentificationType',
  'secondaryIdentificationNumber',
  'secondaryIdentificationDocs',
  'secondaryIdentificationBackDocs'
];

const NewDataForm = ({ userPersonalDetails }) => {
  const { roleKey } = useContext(RoleContext);
  const { id, title } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCompleteProfile = pathname.includes('complete-profile');
  const isPathApproval = pathname.includes('approval');

  const contentKey = title === PB_ROLE_KEY ? PERSONAL_INFO_KEY : BROKERAGE_DETAILS_KEY;

  const [approveSection] = useMutation(APPROVE_USER_BY_ADMIN);

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: title === PB_ROLE_KEY ? PERSONAL_INFO_KEY : BROKERAGE_DETAILS_KEY
    }
  });

  const newFormData = data?.getSectionUpdatedFields?.updatedFields || {};

  const renderPersonalInfo = () => {
    if (!checkPersonalInfoKeys) return;
    if (pathname.includes('brokerage-info')) {
      return null;
    }
    const combineUserAprovalPath =
      pathname.includes('approval') || pathname.includes('user') || pathname.includes('profile');

    const brokerOrPB = title === BROKER_ROLE_KEY || title === PB_ROLE_KEY;
    const rolePbOrBroker = roleKey === BROKER_ROLE_KEY || roleKey === PB_ROLE_KEY;
    if (combineUserAprovalPath && !isCompleteProfile && brokerOrPB) {
      return <ApprovePersonalInfo isForm isNewData={checkPersonalInfoKeys} />;
    }
    if (!isCompleteProfile && isProfilePath && rolePbOrBroker) {
      return <ApprovePersonalInfo isForm />;
    }
  };

  const renderFormActionButtons = (isSubmitting) => {
    if (isPathApproval) {
      return (
        <div className="mt-4 text-end">
          <Button
            type="submit"
            name="approveValue"
            value={false}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button color="primary" type="submit" name="approveValue" value={true}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
  };

  const checkPersonalInfoKeys = checkIfFormValueExistInData(newFormData, personalInfoKeys);
  const checkLicenseKeys = checkIfFormValueExistInData(newFormData, ['licenseNumber']);
  const checkIdentyKeys = checkIfFormValueExistInData(newFormData, identyKeys);
  const checkSecondIdentyKeys = checkIfFormValueExistInData(newFormData, secondIdentyKeys);

  const updatedUserDetail = { ...userPersonalDetails, ...newFormData, approveValue: false };

  const onApprovePersonalDetails = async (data) => {
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updatePBAgentApproveSection(
            userPersonalDetails.approveSections,
            contentKey,
            data.approveValue === 'true' ? true : false
          ),
          status: data.approveValue === 'true' ? 'approved' : 'rejected',
          formSection: contentKey
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      return title === PB_ROLE_KEY
        ? navigate(`/approval/${PB_ROLE_KEY}/${id}/brokerage-details`)
        : navigate(`/approval/broker-agent/${id}/e-sign`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderSelectedBroker = () => {
    return <SelectedBroker userId={userPersonalDetails?.id} />;
  };

  return (
    <Formik
      initialValues={{ ...updatedUserDetail }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={onApprovePersonalDetails}>
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && pathname.includes('approval')) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          {renderPersonalInfo()}
          <Licensing isNewData={checkLicenseKeys} />
          {renderSelectedBroker()}
          <Identification
            isNewData={checkIdentyKeys}
            title="Identification"
            formKey={{
              forntDoc: 'identificationDocs',
              backDoc: 'identificationBackDocs',
              identyType: 'identificationType',
              identyNum: 'identificationNumber'
            }}
            identyTypesKey={{
              primary: 'identificationType',
              secondary: 'secondaryIdentificationType'
            }}></Identification>
          <Identification
            isNewData={checkSecondIdentyKeys}
            title="Secondary Identification"
            secondary
            formKey={{
              forntDoc: 'secondaryIdentificationDocs',
              backDoc: 'secondaryIdentificationBackDocs',
              identyType: 'secondaryIdentificationType',
              identyNum: 'secondaryIdentificationNumber'
            }}
            identyTypesKey={{
              primary: 'identificationType',
              secondary: 'secondaryIdentificationType'
            }}></Identification>
          <ProfilePicture userPersonalDetails={updatedUserDetail} />
          {renderFormActionButtons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default NewDataForm;
