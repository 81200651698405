import React from 'react';
import BusinessAddress from 'pages/Profile/components/MortgageAdmin/components/BusinessInfo/components/BusinessAddress';

const CorporateAddress = ({ isNewData }) => {
  return (
    <div>
      <BusinessAddress isNewData={isNewData} />
    </div>
  );
};

export default CorporateAddress;
