import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import AccountType from '../AccountType';
import BusinessType from '../BusinessType';
import { BUSINESS_INFO_KEY } from 'constant';
import BusinessAddress from '../BusinessAddress';
import BusinessDetails from '../BusinessDetails';
import BusinessContact from '../BusinessContact';
import Identification from 'pages/Profile/common/Identy';
import PrincipalRepresentative from '../PrincipalRepresent';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import { checkIfFormValueExistInData } from 'pages/Profile/Helper';
import {
  businessAddKeys,
  businessContactKeys,
  businessPartnerDetailsKeys,
  maIdentyKeys,
  maSecondIdentyKeys
} from './Helper';
import ProfilePicture from 'pages/Profile/common/ProfilePic';

const NewBusinessDataForm = ({
  userPersonalDetails,
  initialValues,
  onApproveBusinessInfo,
  approveLoading
}) => {
  const { pathname } = useLocation();

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: BUSINESS_INFO_KEY
    }
  });

  const formData = data?.getSectionUpdatedFields?.updatedFields || {};

  const renderFormActionButtons = () => {
    if (pathname.includes('approval')) {
      return (
        <>
          <Button
            color="danger"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}>
            Reject
          </Button>
          <Button
            color="primary"
            className="ms-3"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}>
            Approve Section
          </Button>
        </>
      );
    }
  };

  const checkBusinessPartnerDetails = checkIfFormValueExistInData(
    formData,
    businessPartnerDetailsKeys
  );

  const checkAccountType = checkIfFormValueExistInData(formData, ['accountType']);
  const checkBussinessType = checkIfFormValueExistInData(formData, ['bussinessType']);
  const checkBusinessAdd = checkIfFormValueExistInData(formData, businessAddKeys);
  const checkBusinessContact = checkIfFormValueExistInData(formData, businessContactKeys);
  const checkPosition = checkIfFormValueExistInData(formData, ['principlerepresentativePosition']);
  const checkPrimaryIdenty = checkIfFormValueExistInData(formData, maIdentyKeys);
  const checkSecondIdentyKeys = checkIfFormValueExistInData(formData, maSecondIdentyKeys);

  return (
    <Formik
      initialValues={{ ...initialValues, ...formData }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={onApproveBusinessInfo}>
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && pathname.includes('approval')) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <div className="mt-3">
            <BusinessType isNewData={checkBussinessType} />
          </div>
          <div className="mt-3">
            <BusinessDetails
              isNewData={checkBusinessPartnerDetails}
              title="Mortgage Administrator License Number"
            />
          </div>
          <BusinessAddress isNewData={checkBusinessAdd} />
          <BusinessContact isNewData={checkBusinessContact} />
          <PrincipalRepresentative isNewData={checkPosition} />
          <div className="mt-3">
            <Identification
              title="Principal Representative Identification"
              formKey={{
                forntDoc: 'identificationFrontDocs',
                backDoc: 'identificationBackDocs',
                identyType: 'identificationType',
                identyNum: 'identificationNumber'
              }}
              identyTypesKey={{
                primary: 'identificationType',
                secondary: 'secondaryIdentificationType'
              }}
              isNewData={checkPrimaryIdenty}
            />
          </div>
          <div className="mt-3">
            <Identification
              title="Principal Representative Secondary Identification"
              formKey={{
                forntDoc: 'secondaryIdentificationFrontDocs',
                backDoc: 'secondaryIdentificationBackDocs',
                identyType: 'secondaryIdentificationType',
                identyNum: 'secondary_identification_number'
              }}
              secondary
              identyTypesKey={{
                primary: 'identificationType',
                secondary: 'secondaryIdentificationType'
              }}
              isNewData={checkSecondIdentyKeys}
            />
          </div>
          <div className="text-end mt-4">{renderFormActionButtons(isSubmitting)}</div>
        </form>
      )}
    </Formik>
  );
};

export default NewBusinessDataForm;
