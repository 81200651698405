import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import CombineErrorMessage from 'components/CombineError';

const TrsutInfo = ({ onHandleClick }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.trustName && touched.trustName) ||
      (errors.planType && touched.planType) ||
      (errors.isTrustMember && touched.isTrustMember) ||
      (errors.accountNumber && touched.accountNumber)
    );
  };

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const planTypes = [
    'LIF',
    'LIRA',
    'LRIF',
    'LRSP',
    'RESP',
    'RIF',
    'RIF (Prescribed)',
    'RIF (Spousal)',
    'RLIF',
    'RLSP',
    'RRIF',
    'RRSP',
    'RSP',
    'RSP (Spousal)',
    'SDRSP',
    'TFSA'
  ];

  return (
    <div>
      <Card className="my-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <h3 className="card-heading">Trust Information</h3>
          <div>
            <Row className="input-spacing">
              <Col>
                <Field
                  size="lg"
                  id="trustName"
                  name="trustName"
                  type="text"
                  component={FormikInputField}
                  label="Trust Name"
                  isError={false}
                />
              </Col>
              <Col>
                <Field
                  size="lg"
                  id="planType"
                  name="planType"
                  type="text"
                  component={FormikSelectField}
                  options={planTypes}
                  label="Plan type"
                  isError={false}
                />
              </Col>
            </Row>
            <div className="input-spacing">
              <Field
                size="lg"
                id="accountNumber"
                name="accountNumber"
                type="number"
                component={FormikInputField}
                label="Account Number"
                isError={false}
              />
            </div>
            <div className="input-spacing">
              <Label>
                Are there any persons other than the account holder associated with this trust?
              </Label>
              <div className="d-flex">
                {answer.map(({ label, value }, index) => {
                  return (
                    <div key={label} className={index === 1 ? 'ms-3' : ''}>
                      <Field
                        name="isTrustMember"
                        type="radio"
                        label={label}
                        value={value}
                        onClick={onHandleClick}
                        checked={index === 1 ? !values.isTrustMember : values.isTrustMember}
                        component={FormikRadioField}
                        isError={false}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TrsutInfo;
