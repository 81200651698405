import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import StreetAddress from 'components/common/StreetAddress/index';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import CombineErrorMessage from 'components/CombineError';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import { COUNTRIES, PROVINCES } from 'constant';

const Address = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return (
      (errors.streetAddress1 && touched.streetAddress1) ||
      (errors.city1 && touched.city1) ||
      (errors.province1 && touched.province1) ||
      (errors.country1 && touched.country1) ||
      (errors.postalCode1 && touched.postalCode1) ||
      (errors.isSameAddress && touched.isSameAddress)
    );
  };

  const onHandleClick = (e) => {
    const { value } = e.target;
    if (value !== 'true') {
      setFieldValue('streetAddress2', null);
      setFieldValue('unitNumber2', null);
      setFieldValue('country2', null);
      setFieldValue('province2', null);
      setFieldValue('city2', null);
      setFieldValue('postalCode2', null);
      //
      setFieldTouched('streetAddress2', false);
      setFieldTouched('unitNumber2', false);
      setFieldTouched('country2', false);
      setFieldTouched('province2', false);
      setFieldTouched('city2', false);
      setFieldTouched('postalCode2', false);
    }
  };

  return (
    <div>
      <Card className="mb-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Address</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <div className="input-spacing">
            <StreetAddress
              name="streetAddress1"
              label="Street Address"
              isError={false}
              fillAddress={{
                country: 'country1',
                city: 'city1',
                province: 'province1',
                postalCode: 'postalCode1'
              }}
            />
          </div>
          <Row className="input-spacing">
            <Col md={6} lg={6}>
              <Field
                size="lg"
                id="unitNumber"
                name="unitNumber1"
                type="text"
                component={FormikInputField}
                label="Suite/Unit Number"
                isoptionallabel="true"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6}></Col>
          </Row>

          <Row>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="country"
                name="country1"
                type="text"
                component={FormikSelectField}
                options={COUNTRIES}
                label="Country"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="province"
                name="province1"
                type="text"
                component={FormikSelectField}
                options={PROVINCES}
                label="Province"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="city"
                name="city1"
                type="text"
                component={FormikInputField}
                label="City"
                isError={false}
              />
            </Col>
            <Col md={6} lg={6} className="input-spacing">
              <Field
                size="lg"
                id="postalCode"
                name="postalCode1"
                type="text"
                component={FormikInputField}
                label="Postal Code"
                isError={false}
              />
            </Col>
          </Row>
          <div className="input-spacing">
            <Label>Has this applicant lives at this address for 2 or more years?</Label>
            <div className="d-flex">
              {answer.map(({ label, value }, index) => {
                return (
                  <div key={label} className={index === 1 ? 'ms-3' : ''}>
                    <Field
                      name="isSameAddress"
                      type="radio"
                      label={label}
                      value={value}
                      checked={index === 1 ? !values.isSameAddress : values.isSameAddress}
                      component={FormikRadioField}
                      onClick={onHandleClick}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Address;
