import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import CombineErrorMessage from 'components/CombineError';

const CorporateDetails = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.coorporateLegalName && touched.coorporateLegalName) ||
      (errors.coorporateOperatingName && touched.coorporateOperatingName) ||
      (errors.identificationNumber && touched.identificationNumber) ||
      (errors.issuancePlace && touched.issuancePlace)
    );
  };
  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Corporate Details</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <Row>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                name="coorporateLegalName"
                label="Legal Name"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                name="coorporateOperatingName"
                label="Operating Name"
                component={FormikInputField}
                type="text"
                isError={false}
                isoptionallabel="true"
              />
            </Col>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                name="identificationNumber"
                label="Identification Number"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                name="issuancePlace"
                label="Place of Issuance"
                component={FormikInputField}
                type="text"
                isError={false}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default CorporateDetails;
