import React, { useState, useEffect } from 'react';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';

const InvestmentGoal = ({ isNewData }) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.investmentTimeHorizon && touched.investmentTimeHorizon) ||
      (errors.investmentObjectives && touched.investmentObjectives)
    );
  };
  const timeHorizon = ['1 to 2 Years', '3 to 5 Years', '6 to 9 Years', 'Over 10 Years'];
  const objectives = ['Income', 'Moderate growth', 'Aggressive growth', 'Long term growth'];

  return (
    <div>
      <Card className="mt-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Investment Goal</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <div className="input-spacing">
            <Label>Please indicate your investment time horizon</Label>
            <Row>
              {timeHorizon.map((item, index) => {
                return (
                  <Col xl={5} key={item}>
                    <Field
                      size="lg"
                      key={item}
                      id={item}
                      // type="radio"
                      name="investmentTimeHorizon"
                      label={item}
                      component={FormikRadioboxField}
                      isError={false}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="input-spacing">
            <Label>Please state your investment objectives</Label>
            <Row>
              {objectives.map((item, index) => {
                return (
                  <Col md={5} lg={5} key={item}>
                    <Field
                      size="lg"
                      key={item}
                      id={item}
                      // type="radio"
                      name="investmentObjectives"
                      label={item}
                      component={FormikRadioboxField}
                      isError={false}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default InvestmentGoal;
